"use client";

import Search from "@/app/(website)/(listings)/projects/_components/search";
import { Phone } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { openResaleListingModal } from "./resale-unit-form";

export const BNHPhone = "+917087429249";

export function NavbarComponent() {
  const onPostYourPropertyClick = () => {
    openResaleListingModal({ source: "post_property" });
  };

  const posthog = usePostHog();

  return (
    <nav className="bg-background-400 bg-opacity-90 max-md:shadow-md border-b border-gray-300">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 py-2">
        <div className="flex items-center gap-6 md:gap-16 h-16 w-full">
          {/* Logo */}
          <Link href="/" className="flex-shrink-0">
            <Image
              className="h-8 w-auto"
              src="/logos/bnHabitatLogo.svg"
              alt="Habitat"
              width={200}
              height={60}
            />
          </Link>

          {/* Desktop Menu */}
          <div className="flex items-center gap-4 md:gap-16 flex-shrink-0 flex-1">
            {/* Search Bar */}
            <Search />
            {/* Right-aligned items */}
            <div className="flex items-center gap-4 flex-shrink-0">
              {/* Call Us */}
              <Link
                href={`tel:${BNHPhone}`}
                onClick={() => {
                  posthog.capture("call_us_clicked", {
                    source: "property_list_navbar",
                  });
                }}
                className="border border-gray-300 md:border-none flex items-center justify-center p-2 md:p-3 hover:bg-gray-100 active:bg-gray-300 rounded-lg md:rounded-full"
              >
                <Phone className="flex-shrink-0 h-6 w-6 text-primary" />
              </Link>
              {/* Separator */}
              <div className="max-md:hidden h-12 border-r border-gray-300" />
              {/* Post Property Button */}
              <button
                onClick={onPostYourPropertyClick}
                className="max-md:hidden flex-shrink-0 px-12 py-3.5 border border-transparent text-sm rounded-md text-primary-50 bg-primary-950 hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                style={{
                  boxShadow:
                    "0px 4px 8px 0px rgba(45, 32, 17, 0.20), 0px 1.5px 3px 0px rgba(45, 32, 17, 0.20), 0px 1px 1px 0px rgba(45, 32, 17, 0.12), 0px -3px 0px 0px rgba(0, 0, 0, 0.75) inset",
                }}
              >
                Post property
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

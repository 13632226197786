"use client";

import LoadingModal from "@/components/loading-modal";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { FilterIcon } from "lucide-react";
import dynamic from "next/dynamic";
import { useState } from "react";

interface FilterProps {
  searchParams?: { [key: string]: string };
  className?: string;
}

const FilterModal = dynamic(() => import("./filter-modal"), {
  ssr: false,
  loading: () => <LoadingModal />,
});

export default function Filter({ searchParams, className }: FilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="outline"
        className={cn(
          "px-3 py-2 rounded-md flex flex-row text-base items-center gap-2 font-normal border border-background-800 bg-background-50  text-primary hover:bg-background-400 active:bg-gray-100 transition-colors duration-300",
          className
        )}
        onClick={() => setIsOpen(true)}
      >
        <span className=" text-primary-800">Filter</span>
        <FilterIcon className="w-4 h-4 md:w-5 md:h-5" />
      </Button>

      {isOpen && (
        <FilterModal
          open={isOpen}
          searchParams={searchParams}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
}

"use client";

import { VerifiedIcon } from "@/components/icons";
import { BNHPhone } from "@/components/navbar";
import { SeparatorComponent } from "@/components/separator";
import { Mail, Phone } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";

const CompanyDetails = () => {
  const posthog = usePostHog();

  return (
    <>
      <div className="flex flex-col md:flex-row gap-6 md:gap-12 mx-auto w-full max-w-5xl">
        <div
          className=" flex flex-col gap-2 rounded-2xl items-center w-full md:w-1/3 p-8 md:p-10 h-fit border border-background-600"
          style={{
            boxShadow:
              " 0px 118px 33px 0px rgba(125, 125, 125, 0.00), 0px 75px 30px 0px rgba(125, 125, 125, 0.01), 0px 42px 25px 0px rgba(125, 125, 125, 0.05), 0px 19px 19px 0px rgba(125, 125, 125, 0.09), 0px 5px 10px 0px rgba(125, 125, 125, 0.10)",
          }}
        >
          <Image
            src="/logos/bnHabitatLogo.svg"
            alt="bn-habitat-logo"
            width={64}
            height={64}
          />
          <h3 className="text-3xl text-primary-900 font-bold text-center">
            BN Habitat
          </h3>
          <p className="text-base text-primary-700 text-center mt-2 line-clamp-1 flex-shrink-0">
            Established on September 2007
          </p>
        </div>
        <div className="flex flex-col gap-4 md:gap-6 w-full md:w-2/3">
          <h4 className="text-lg text-primary-950 font-semibold">
            Company details
          </h4>
          <div className="flex flex-col gap-1">
            <div className="flex text-base w-full break-words text-nowrap">
              <VerifiedIcon className="mt-1 mr-1 text-blue-600 flex-shrink-0 group-hover:text-primary-50 transition-colors duration-300" />
              <span className="text-primary-800 font-medium flex-shrink-0">
                RERA Reg.:
              </span>
              <div className="text-primary-700 ml-2 text-wrap max-md:w-2/3">
                PBRERA-HRY06-REA0046
              </div>
            </div>
            <div className="flex text-base">
              <span className="text-primary-800 font-medium flex-shrink-0">
                GST No.:
              </span>{" "}
              <span className="text-primary-700 ml-2">03AAECB5185C1Z3</span>
            </div>
          </div>
          <div className="w-full flex flex-col gap-1">
            <iframe
              width={1000}
              src={`https://www.google.com/maps?q=${30.63829156354774},${76.82490051250304}&hl=es;z=14&output=embed`}
              height={700}
              className="border border-background-700 rounded-lg w-full h-20"
              loading="lazy"
            ></iframe>
            <div className="flex justify-between">
              <span className="text-primary-900 text-sm font-medium">
                Zirakpur, Punjab, India
              </span>
              <Link
                href="https://maps.app.goo.gl/4UMnQkMfX6ruRWsF7"
                target="_blank"
                className="text-info-500 hover:text-info-600 text-sm font-medium underline"
              >
                View map
              </Link>
            </div>
          </div>
          <SeparatorComponent className="md:mt-2" />
          <h4 className="text-lg text-primary-950 font-semibold">
            Company details
          </h4>
          <div className="flex flex-col gap-1">
            <div className="flex text-base text-primary-800 font-medium flex-shrink-0">
              <Phone className="w-4 h-4 mt-1 mr-2" />
              <span> Phone No.:</span>{" "}
              <Link
                href={`tel:${BNHPhone}`}
                onClick={() => {
                  posthog.capture("call_us_clicked", {
                    source: "company_details",
                  });
                }}
                className="text-primary-700 ml-2 md:ml-4 hover:underline"
              >
                +91 7087429249
              </Link>
            </div>

            <div className="flex text-base text-primary-800 font-medium flex-shrink-0">
              <Mail className="w-4 h-4 mt-1 mr-2" />
              <span>Email.:</span>{" "}
              <Link
                href={`mailto:contact@bnhabitat.com`}
                onClick={() => {
                  posthog.capture("email_us_clicked", {
                    source: "company_details",
                  });
                }}
                className="text-primary-700 ml-2 md:ml-4 hover:underline"
              >
                contact@bnhabitat.com
              </Link>
            </div>
          </div>

          <Link
            className="w-full md:w-fit text-sm xl:text-base text-center text-primary-50 font-semibold bg-primary-950 hover:bg-primary-900 rounded-xl flex justify-center items-center px-12 py-4 gap-2"
            style={{
              boxShadow:
                "0px 12px 24px -6px rgba(45, 32, 17, 0.30), 0px 4px 8px 0px rgba(45, 32, 17, 0.20), 0px 1.5px 3px 0px rgba(45, 32, 17, 0.20), 0px 1px 1px 0px rgba(45, 32, 17, 0.12), 0px -3px 0px 0px rgba(0, 0, 0, 0.75) inset",
            }}
            href={`mailto:contact@bnhabitat.com`}
          >
            <Mail />
            Career at BN Habitat
          </Link>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;

"use client";

import Link from "next/link";
import { usePostHog } from "posthog-js/react";

const FooterContactUs = () => {
  const posthog = usePostHog();

  return (
    <div className="flex flex-col gap-2">
      <Link
        href={`tel:+917087429249`}
        onClick={() => {
          posthog.capture("call_us_clicked", {
            source: "footer",
          });
        }}
        className="mt-2 text-primary-900 md:text-xs text-sm hover:underline"
      >
        +91 7087429249
      </Link>

      <Link
        href={`mailto:contact@bnhabitat.com`}
        onClick={() => {
          posthog.capture("email_us_clicked", {
            source: "footer",
          });
        }}
        className="text-primary-900 md:text-xs text-sm hover:underline"
      >
        contact@bnhabitat.com
      </Link>
    </div>
  );
};

export default FooterContactUs;
